.loader {
	position: relative;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: left !important;
	flex-wrap: wrap;

	& img {
		width: 15%;
		animation: crescendo 1.3s alternate infinite ease-in;
	}

	& .outer,
	.middle,
	.inner {
		border: 5px solid transparent;
		// border-top-color: $color-active;
		// border-right-color: $color-active;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
	}

	& .outer {
		width: 16.5em;
		height: 16.5em;
		margin-left: -8.7em;
		margin-top: -8.7em;
		animation: spin 2s linear infinite;
		// border-top-color: $color-normal;
		// border-right-color: $color-normal;
	}

	& .middle {
		width: 14.5em;
		height: 14.5em;
		margin-left: -7.75em;
		margin-top: -7.75em;
		animation: spin 1.75s linear reverse infinite;
		// border-top-color: $color-hover;
		// border-right-color: $color-hover;
	}

	& .inner {
		width: 12.5em;
		height: 12.5em;
		margin-left: -6.75em;
		margin-top: -6.75em;
		animation: spin 1.5s linear infinite;
		// border-top-color: $color-active;
		// border-right-color: $color-active;
	}
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

@keyframes crescendo {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.5);
	}
}
