body {
	margin: 0;

	font-family: 'Montserrat Regular';
	// font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 18px;
	color: rgba(0, 0, 0, 0.54) !important;
}

#root::after {
	content: '';
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: fixed;
	z-index: -1;
	background-repeat: no-repeat;
	background-position-y: 100%;
	background-position-x: center;
	background-size: cover;
	background-position: center;
	// background-color: rgba(205, 205, 205, 26%);
}

h1 {
	color: rgba(0, 0, 0, 0.54) !important;
}

.full {
	width: 100%;
}

.center {
	text-align: center;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.bold {
	font-weight: bold !important;
}

.cursor {
	cursor: pointer;
}

.blanco {
	color: #fff !important;
}

.padre {
	display: table;

	& .hijos {
		display: table-cell;
		vertical-align: middle;
	}
}

.mb30 {
	margin-bottom: 30px;
}

.mt30 {
	margin-top: 30px;
}

.mb60 {
	margin-bottom: 60px;
}

.mt60 {
	margin-top: 60px;
}

.MuiInputBase-root {
	// color: red !important;
	font-family: 'Montserrat Regular';
}

// .MuiInputBase-input {
// 	font-family: 'Montserrat Regular';
// 	color: #0000008a !important;
// 	font-size: 18px !important;
// }

.MuiInput-underline {
	&::after {
		border-bottom: 2px solid #0000008a !important;
	}

	&::before {
		border-bottom: 2px solid #0000008a !important;
	}
}

.MuiFormLabel-root {
	font-family: 'Montserrat Regular' !important;

	&.Mui-focused {
		color: #0000008a !important;
		font-family: 'Montserrat Regular' !important;
	}
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
	transform: translate(0px, -18px) scale(1) !important;
	font-family: 'Montserrat Regular' !important;
	color: #0000008a !important;
	font-size: 16px !important;
}

.MuiFilledInput-underline {
	&::before,
	&::after {
		border: none !important;
	}
}

.MuiFormLabel-root.Mui-focused {
	font-weight: normal;
	font-size: 18px !important;
}

.MuiTypography-body1 {
	font-family: 'Monserrat Regular' !important;
}

label.MuiFormControlLabel-root {
	font-family: 'Monserrat Regular';
	font-size: 18px !important;
}

@media only screen and (max-width: 600px) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}
